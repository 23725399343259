import { PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useCallback, useContext, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Constants } from "../../../../../utilities/Constants";
import { getAxiosRequestConfig } from "../../../../../utilities/getAxiosRequestConfig";
import { ContentCard } from "../../../../ContentCard/ContentCard";
import axios from "axios";
import { AuthContext } from "../../../../Auth/AuthContext";
import { Spinner } from "../../../../Spinner/Spinner";
import { TextInput } from "../../../../TextInput/TextInput";
import { CopyToClipboardField } from "../../../../CopyToClipboardField/CopyToClipboardField";

interface IGenerateDownloadLinkJobResponse {
    readonly token: string;
}

const postGenerateDownloadLink = async (token: string | null, ids: string): Promise<IGenerateDownloadLinkJobResponse> => {
    const config = getAxiosRequestConfig(token);
    const gameIds = ids.split(",");
    const { data } = await axios.post(`${Constants.ApiRoot}/job/prepare-replay-download-link`, { gameIds }, config);
    return data;
};

export const DownloadReplaysByGameId: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { token } = useContext(AuthContext);
    const [gameIds, setGameIds] = useState("");
    const [downloadLink, setDownloadLink] = useState<string | null>(null);
    const { mutate, status } = useMutation(() => postGenerateDownloadLink(token, gameIds), {
        onSuccess: (data) => {
            const { token } = data;
            const link = `${Constants.ApiRoot}/job/download-replays/${token}`;
            setDownloadLink(link);
        },
        onError: (_error, _variables, _context) => {},
    });

    const onDownloadClicked = useCallback(() => {
        mutate();
    }, [mutate]);

    const onChange = useCallback(
        (_: unknown, value: any) => {
            setGameIds(value);
        },
        [setGameIds]
    );

    if (status === "loading") {
        return <Spinner />;
    }
    return (
        <ContentCard>
            <TextInput onChange={onChange} value={gameIds} type="text" label="Comma separated game ids" />
            <PrimaryButton onClick={onDownloadClicked}>Prepare download link</PrimaryButton>
            {downloadLink !== null && <CopyToClipboardField value={downloadLink} />}
        </ContentCard>
    );
};
