import * as React from "react";
import { WithAuth } from "../../../Auth/WithAuth";
import { AdminContent } from "./AdminContent";

const Admin: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <WithAuth adminOnly={true}>
            <AdminContent />
        </WithAuth>
    );
};

export default Admin;
