import { PrimaryButton, Spinner } from "@fluentui/react";
import * as React from "react";
import { useMutation } from "@tanstack/react-query";
import { Constants } from "../../../../../utilities/Constants";
import { getAxiosRequestConfig } from "../../../../../utilities/getAxiosRequestConfig";
import { AuthContext } from "../../../../Auth/AuthContext";
import { ContentCard } from "../../../../ContentCard/ContentCard";
import axios from "axios";

const postSendLeaderboardEmail = async (token: string | null): Promise<unknown> => {
    const config = getAxiosRequestConfig(token);
    const { data } = await axios.post(`${Constants.ApiRoot}/job/send-leaderboard-email`, undefined, config);
    return data;
};

export const SendLeaderboardEmail: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { token } = React.useContext(AuthContext);

    const { mutate: updateTrueskill, status } = useMutation(() => postSendLeaderboardEmail(token), {
        onSuccess: (_data) => {},
        onError: (_error, _variables, _context) => {},
    });
    const onGenerateTrueSkillClicked = React.useCallback(() => {
        updateTrueskill();
    }, [updateTrueskill]);

    if (status === "loading") {
        return <Spinner />;
    }
    return (
        <ContentCard>
            <PrimaryButton onClick={onGenerateTrueSkillClicked}>Send email</PrimaryButton>
        </ContentCard>
    );
};
