import { PrimaryButton } from "@fluentui/react";
import axios from "axios";
import * as React from "react";
import { useCallback, useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { usePaypalPlans } from "../../../../../hooks/api/admin/usePaypalPlans";
import { Constants } from "../../../../../utilities/Constants";
import { getAxiosRequestConfig } from "../../../../../utilities/getAxiosRequestConfig";
import { AuthContext } from "../../../../Auth/AuthContext";
import { ContentCard } from "../../../../ContentCard/ContentCard";
import { Spinner } from "../../../../Spinner/Spinner";

const postSyncPaypalPlansJob = async (token: string | null): Promise<unknown> => {
    const config = getAxiosRequestConfig(token);
    const { data } = await axios.post(`${Constants.ApiRoot}/job/sync-paypal-plans`, undefined, config);
    return data;
};

export const SyncPaypalPlans: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { token } = useContext(AuthContext);
    const { isLoading, data } = usePaypalPlans();
    const { mutate: createJobs, status } = useMutation(() => postSyncPaypalPlansJob(token), {
        onSuccess: (_data) => {},
        onError: (_error, _variables, _context) => {},
    });

    const onGenerateJobsClicked = useCallback(() => {
        createJobs();
    }, [createJobs]);
    if (status === "loading" || isLoading === true) {
        return <Spinner />;
    }
    return (
        <React.Fragment>
            <ContentCard>
                <PrimaryButton onClick={onGenerateJobsClicked}>Sync paypal plans</PrimaryButton>
            </ContentCard>
            <ContentCard>
                <pre>{JSON.stringify(data, null, 4)}</pre>
            </ContentCard>
        </React.Fragment>
    );
};
