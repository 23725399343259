import { Checkbox, PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useCallback, useContext, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useValidSubmissions } from "../../../../../hooks/api/admin/useValidSubmissions";
import { Constants } from "../../../../../utilities/Constants";
import { getAxiosRequestConfig } from "../../../../../utilities/getAxiosRequestConfig";
import { AuthContext } from "../../../../Auth/AuthContext";
import { ContentCard } from "../../../../ContentCard/ContentCard";
import { Spinner } from "../../../../Spinner/Spinner";
import axios from "axios";

const postGenerateRoundRobinFromSubmissionIds = async (token: string | null, submissionIds: Array<string>): Promise<unknown> => {
    const config = getAxiosRequestConfig(token);
    const { data } = await axios.post(`${Constants.ApiRoot}/job/generate-games-from-submissions`, { submissionIds }, config);
    return data;
};

export const RoundRobinFromSubmissions: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { token } = useContext(AuthContext);
    const { isLoading, data } = useValidSubmissions();
    const [selection, setSelection] = useState<Array<string>>([]);
    const submissionList = useMemo(() => {
        const selected = new Map<string, number>();
        selection.forEach((_id, index) => {
            selected.set(_id, index);
        });
        return data?.map((submission) => {
            const { image, alias, _id } = submission;
            const label = `[${alias}] ${image}`;

            const selectedIndex = selected.get(_id);
            const isSelected = selectedIndex !== undefined;
            const onClick = () => {
                const updatedSelection = [...selection];
                if (selectedIndex !== undefined) {
                    updatedSelection.splice(selectedIndex, 1);
                } else {
                    updatedSelection.push(_id);
                }
                setSelection(updatedSelection);
            };

            return <Checkbox key={_id} checked={isSelected} onChange={onClick} label={label} />;
        });
    }, [selection, data]);

    const { mutate, status } = useMutation(() => postGenerateRoundRobinFromSubmissionIds(token, selection), {
        onSuccess: (_data) => {},
        onError: (_error, _variables, _context) => {},
    });

    const onSubmit = useCallback(() => {
        mutate();
    }, [selection]);

    if (isLoading || status === "loading") {
        return <Spinner />;
    }

    return (
        <ContentCard>
            {submissionList}
            <PrimaryButton onClick={onSubmit}>Generate round robin from submission ids</PrimaryButton>
        </ContentCard>
    );
};
