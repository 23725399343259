import { PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useCallback, useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { Constants } from "../../../../../utilities/Constants";
import { getAxiosRequestConfig } from "../../../../../utilities/getAxiosRequestConfig";
import { ContentCard } from "../../../../ContentCard/ContentCard";
import axios from "axios";
import { AuthContext } from "../../../../Auth/AuthContext";
import { Spinner } from "../../../../Spinner/Spinner";

const postGenerateGameJobs = async (token: string | null): Promise<unknown> => {
    const config = getAxiosRequestConfig(token);
    const { data } = await axios.post(`${Constants.ApiRoot}/job/generate-games`, undefined, config);
    return data;
};

export const RoundRobin: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { token } = useContext(AuthContext);
    const { mutate: createJobs, status } = useMutation(() => postGenerateGameJobs(token), {
        onSuccess: (_data) => {},
        onError: (_error, _variables, _context) => {},
    });

    const onGenerateJobsClicked = useCallback(() => {
        createJobs();
    }, [createJobs]);

    if (status === "loading") {
        return <Spinner />;
    }
    return (
        <ContentCard>
            <PrimaryButton onClick={onGenerateJobsClicked}>Generate round robin</PrimaryButton>
        </ContentCard>
    );
};
