import { PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useCallback } from "react";
import { ContentCard } from "../../../../ContentCard/ContentCard";
import { TextInput } from "../../../../TextInput/TextInput";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { Constants } from "../../../../../utilities/Constants";
import { getAxiosRequestConfig } from "../../../../../utilities/getAxiosRequestConfig";
import { AuthContext } from "../../../../Auth/AuthContext";
import { Spinner } from "../../../../Spinner/Spinner";

const postStartGameWorker = async (token: string | null, workerCount: number): Promise<unknown> => {
    const config = getAxiosRequestConfig(token);
    const { data } = await axios.post(`${Constants.ApiRoot}/job/start-game-worker`, { count: workerCount }, config);
    return data;
};

export const StartWorkersWithCount: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { token } = React.useContext(AuthContext);
    const [workerCount, setWorkerCount] = React.useState(1);

    const { mutate: startGameWorker, status } = useMutation(() => postStartGameWorker(token, workerCount), {
        onSuccess: (_data) => {},
        onError: (_error, _variables, _context) => {},
    });

    const onStartGameWorkerClicked = useCallback(() => {
        startGameWorker();
    }, [startGameWorker]);

    const onCountChanged = React.useCallback(
        (_: unknown, newValue: string | undefined) => {
            const updatedValue = newValue === undefined ? 1 : parseInt(newValue);
            setWorkerCount(updatedValue);
        },
        [setWorkerCount]
    );

    if (status === "loading") {
        return <Spinner />;
    }
    return (
        <ContentCard>
            <TextInput label="Start worker count" type="number" value={workerCount.toString()} onChange={onCountChanged} />
            <PrimaryButton onClick={onStartGameWorkerClicked}>Start game worker</PrimaryButton>
        </ContentCard>
    );
};
