import { Pivot, PivotItem, Stack } from "@fluentui/react";
import * as React from "react";
import { RoundRobinFromSubmissions } from "./AdminControls/RoundRobinFromSubmissions";
import { RoundRobin } from "./AdminControls/RoundRobin";
import { StartWorkersWithCount } from "./AdminControls/StartWorkersWithCount";
import { DownloadReplaysByGameId } from "./AdminControls/DownloadReplaysByGameId";
import { SendLeaderboardEmail } from "./AdminControls/SendLeaderboardEmail";
import { MigrateData } from "./AdminControls/MigrateData";
import { SyncPaypalPlans } from "./AdminControls/SyncPaypalPlans";
import { ResetTrueSkill } from "./AdminControls/ResetTrueSkill";
import { SyncPaypalProducts } from "./AdminControls/SyncPaypalProducts";

export const AdminContent: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <React.Fragment>
            <Stack>
                <Pivot overflowBehavior="menu">
                    <PivotItem headerText="Send leaderboard email">
                        <SendLeaderboardEmail />
                    </PivotItem>
                    <PivotItem headerText="Download replays by game id">
                        <DownloadReplaysByGameId />
                    </PivotItem>
                    <PivotItem headerText="Round robin">
                        <RoundRobin />
                    </PivotItem>
                    <PivotItem headerText="Start worker">
                        <StartWorkersWithCount />
                    </PivotItem>
                    <PivotItem headerText="Round robin from submissions">
                        <RoundRobinFromSubmissions />
                    </PivotItem>
                    <PivotItem headerText="Migrate data">
                        <MigrateData />
                    </PivotItem>
                    <PivotItem headerText="Reset trueskill">
                        <ResetTrueSkill />
                    </PivotItem>
                    <PivotItem headerText="Paypal products">
                        <SyncPaypalProducts />
                    </PivotItem>
                    <PivotItem headerText="Paypal plans">
                        <SyncPaypalPlans />
                    </PivotItem>
                </Pivot>
            </Stack>
        </React.Fragment>
    );
};
