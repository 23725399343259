import axios, { AxiosRequestConfig } from "axios";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { Constants } from "../../../utilities/Constants";
import { getAxiosRequestConfig } from "../../../utilities/getAxiosRequestConfig";

export interface IPaypalPlan {
    readonly _id: string;
    readonly image: string;
    readonly created: Date;
    readonly _teamId: string;
    readonly alias: string;
}

export type PaypalPlansResponse = Array<IPaypalPlan>;

const getPaypalPlans = async (config: AxiosRequestConfig) => {
    const uri = `${Constants.ApiRoot}/plans`;
    const { data } = await axios.get<PaypalPlansResponse>(uri, config);
    return data;
};

export const usePaypalPlans = () => {
    const { token } = useContext(AuthContext);
    const config = getAxiosRequestConfig(token);
    return useQuery(["paypal-plans"], () => getPaypalPlans(config));
};
